import './App.css';

import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Footer from './components/Footer';
import Header from './components/Header';
import Index from './pages/Index';
import Submitted from './pages/Submitted';

/**
 * The App component is a TypeScript React component that renders a header, a router with different
 * routes, and a footer.
 * @returns The App component is returning a JSX element. The JSX element represents the structure and
 * content of the application's user interface. It consists of a div with the className 'App', a Header
 * component, a Router component with nested Route components, and a Footer component.
 */
function App() {
  const { i18n } = useTranslation();

  const onLanguageChange = () => {
    const currentLanguage = i18n.language;
    i18n.changeLanguage(currentLanguage === 'cz' ? 'en' : 'cz');
    (document.activeElement as any).blur();
  };

  return (
    <div className='App'>
      <Header onLanguageChange={onLanguageChange} />
      <Router>
        <Routes>
          <Route path='/' element={<Index />} />
          <Route path='/prikaz-odeslan' element={<Submitted />} />
          <Route path='/request-submitted' element={<Submitted />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
