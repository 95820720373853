import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';

import FileUploadField from './FileUploadField';
import Submit from './Submit';
import TextAreaField from './TextAreaField';
import TextField from './TextField';

interface Props {
  onFormSubmitted: () => void;
}

interface Data {
  sender: string;
  topic: string;
  text: string;
}

/* The code defines a functional component called `SPOCForm` which is a form component in a TypeScript
React application. It takes a prop `onFormSubmitted` which is a callback function to be executed
when the form is submitted. */
const SPOCForm: React.FC<Props> = ({ onFormSubmitted }: Props) => {
  const { t } = useTranslation();

  const [sender, setSender] = useState<string>('');
  const [topic, setTopic] = useState<string>('');
  const [text, setText] = useState<string>('');
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const [senderError, setSenderError] = useState<string>('');
  const [topicError, setTopicError] = useState<string>('');
  const [textError, setTextError] = useState<string>('');
  const [uploadedFilesErrorFormat, setUploadedFilesErrorFormat] = useState<
    string[]
  >([]);
  const [uploadedFilesErrorSize, setUploadedFilesErrorSize] =
    useState<boolean>(false);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

 /**
  * The function `handleFileChange` is used to handle the change event when a file is selected in an
  * input element, and it performs file upload logic by adding the selected files to the
  * `uploadedFiles` state array.
  * @param event - The event parameter is of type React.ChangeEvent<HTMLInputElement>. It represents
  * the event that is triggered when the user selects a file in an input element of type "file".
  */
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      // Perform file upload logic here
      const newFiles: File[] = [];
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files.item(i);
        if (file !== null && !uploadedFiles.some((e) => e.name === file.name)) {
          newFiles.push(file);
        }
      }

      newFiles.push(...uploadedFiles);

      setUploadedFiles(newFiles);
    }
  };
  /**
   * The function `handleFileDelete` removes a file from an array of uploaded files based on its index.
   * @param {number} index - The `index` parameter is the index of the file that needs to be deleted
   * from the `uploadedFiles` array.
   */
  const handleFileDelete = (index: number) => {
    const newFiles = uploadedFiles.filter((e, i) => {
      return i !== index;
    });
    setUploadedFiles(newFiles);
  };

  /**
   * The handleSubmit function is an asynchronous function that handles form submission by validating
   * the form data, creating a FormData object, appending the form data and uploaded files to it, and
   * then making a POST request to the specified email endpoint.
   * @param {any} event - The `event` parameter is an object that represents the event that triggered
   * the form submission. It contains information about the event, such as the target element, the type
   * of event, and any additional data associated with the event. In this case, the `event` parameter
   * is used to prevent the default
   */
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!isSubmitLoading) {
      setIsSubmitDisabled(true);

      const formIsValid = validate();

      if (formIsValid) {
        setIsSubmitLoading(true);
        try {
          const formData = new FormData();

          const data: Data = { sender, topic, text };

          formData.append('data', JSON.stringify(data));

          for (let i = 0; i < uploadedFiles.length; i++) {
            const file = uploadedFiles[i];
            formData.append('files', file);
          }

          if (process.env.REACT_APP_EMAIL_ENDPOINT) {
            const res = await fetch(process.env.REACT_APP_EMAIL_ENDPOINT, {
              method: 'POST',
              body: formData,
              keepalive: true,
            });
            onFormSubmitted();

            console.log(res);
          }
        } catch (e) {
          onFormSubmitted();
          console.log(e);
        }
      } else {
        setIsSubmitDisabled(false);
      }
    }
  };

  /**
   * The function "validate" checks if the sender, topic, text area, and files are valid and returns a
   * boolean indicating if there are any errors.
   * @returns a boolean value. It returns true if there are no errors in the validation process, and
   * false if there are any errors.
   */
  const validate = () => {
    let errored = false;
    // Validate sender
    if (!!sender) setSenderError('');
    else {
      errored = true;
      setSenderError(t('form.errors.requiredField'));
    }

    // Validate topic
    if (!!topic) setTopicError('');
    else {
      errored = true;
      setTopicError(t('form.errors.requiredField'));
    }

    // Validate text area
    if (!!text) setTextError('');
    else {
      errored = true;
      setTextError(t('form.errors.requiredField'));
    }
    // Validate files
    if (!validateFiles()) errored = true;

    return !errored;
  };

  /**
   * The function `validateFiles` checks the validity and size of uploaded files based on the accepted
   * formats and maximum file size specified in the environment variables, and returns true if all
   * files are valid and within the size limit.
   * @returns The function `validateFiles` returns a boolean value. It returns `true` if there are no
   * invalid files and the overall size of the files is less than or equal to the limit. Otherwise, it
   * returns `false`.
   */
  const validateFiles = () => {
    let validMimes = process.env.REACT_APP_ACCEPTED_FORMATS?.split(', ');
    let invalidFiles = [];
    let overallSize = 0;
    let limit = Number.MAX_SAFE_INTEGER;

    if (process.env.REACT_APP_MAX_FILES_SIZE) {
      limit = Number.parseInt(process.env.REACT_APP_MAX_FILES_SIZE);
    }

    for (let i = 0; i < uploadedFiles.length; i++) {
      const file = uploadedFiles[i];

      // Check file
      if (validMimes !== undefined && validMimes?.length !== 0) {
        if (!validMimes.includes(file.type)) {
          invalidFiles.push(file.name);
        }
      }

      // Check file size
      overallSize += file.size;
    }

    console.log(`${overallSize} > ${limit}`);

    if (overallSize > limit) {
      setUploadedFilesErrorSize(true);
    } else {
      setUploadedFilesErrorSize(false);
    }

    setUploadedFilesErrorFormat(invalidFiles);

    return invalidFiles.length === 0 && overallSize <= limit;
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <Row>
        <TextField
          label={t('form.sender')}
          name='sender'
          value={sender}
          onChange={setSender}
          required
          error={senderError}
        />
        <TextField
          label={t('form.topic')}
          name='topic'
          value={topic}
          onChange={setTopic}
          required
          error={topicError}
        />
      </Row>
      <TextAreaField
        label={t('form.text')}
        name='text'
        value={text}
        onChange={setText}
        required
        error={textError}
      />
      <FileUploadField
        label={t('form.file')}
        name='files[]'
        onFileChange={handleFileChange}
        filesSelected={uploadedFiles}
        handleDelete={handleFileDelete}
        required={false}
        overSizeLimitError={uploadedFilesErrorSize}
        invalidFiles={uploadedFilesErrorFormat}
      />

      {process.env.REACT_APP_RECAPTCHA_SITE_KEY && (
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          size='invisible'
        />
      )}

      <Submit disabled={isSubmitDisabled} loading={isSubmitLoading} />
    </FormContainer>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 20px;
  }
`;

const FormContainer = styled.form`
  max-width: 630px;
  width: 90%;
  position: relative;
  margin: 0 auto;
`;

export default SPOCForm;
