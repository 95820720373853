import { styled } from 'styled-components';

const TitleText = ({ title }: { title: string }) => {
  return (
    <TitleTextContainer>
      <H1>{title}</H1>
    </TitleTextContainer>
  );
};

export default TitleText;

const H1 = styled.h1`
  color: #1b4689;
  display: block;
  font-size: 30px;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  font-family: Gotham, sans-serif;
  line-height: 1.1;
  text-align: center;
`;

const TitleTextContainer = styled.div`
  display: grid;
  align-content: center;
  width: 70%;
  padding-inline: 1rem;
`;
